.repo-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 0.5rem 2rem;
  width: 384px;
  height: 401px;
}
.repo-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.repo-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.repo-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-name-div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name {
  /* Existing styles */
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  /* ... other styles */

  /* Truncation and hover behavior */
  display: -webkit-box; /* Safari, Chrome, etc. */
  -webkit-line-clamp: 1; /* Safari, Chrome, etc. */
  -webkit-box-orient: vertical; /* Safari, Chrome, etc. */
  overflow: hidden; /* Clip content that overflows */

  /* Hover state styles */
  cursor: pointer; /* Indicate hover interaction */
  transition: all 0.2s ease-in-out; /* Smooth transition */

  /* Removed unnecessary properties */
  /* margin: 0px;  */ /* Not needed for truncation */
  /* line-height: 1.2;  */ /* Not needed for truncation */

  /* Define a pseudo-element for the full text display */
  &::after {
    content: attr(data-full-text); /* Use data attribute to store full text */
    display: none; /* Initially hidden */
    padding: 8px; /* Add padding for visual separation */
    background-color: black;
    color: white; /* Ensure text is visible on black background */
    position: absolute; /* Position below the main content */
    top: 15%; /* Start below the element */
    left: 0; /* Align with the left edge */
    width: 100%; /* Match container width */
    text-align: left; /* Maintain left alignment */
    white-space: normal; /* Allow text wrapping */
    z-index: 1; /* Ensure tooltip is above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    font-size: large;
  }

  /* Show full text on hover */
  &:hover::after {
    display: block; /* Make it visible */
  }
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.repo-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.repo-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

.repo-languages {
  margin-left: auto;
  margin-right: 0px;
}

@media (max-width: 1200px) {
  .repo-card-div {
    /* flex: 1 1 40%; */
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .repo-card-div {
    margin-left: 40px;
    margin-right: 40px;
    /* flex: 1 1 100%; */
    width: 581px;
    height: 480px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .repo-card-div {
    /* margin-left: 40px;
    margin-right: 40px;
    margin-left: 10px;
    margin-right: 10px; */
    width: 312px;
    height: 367px;
  }

  .repo-name {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}
